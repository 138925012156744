import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Monitor Press Mentions"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>Corporate news and press releases are hard to predict. A company may release important information or
            updates at any moment, whether it is in the dead of night or just after the close of business on any given Friday.
              The sooner you know the latest news, the sooner you can take action and stay ahead of the curve.</p>
            <p>Monitoring the press releases and news sections of competitors, suppliers, partners, or market leaders is a great way
            to stay informed. However, not every press release or update will be useful, so it is essential that you have the
                tools to filter out the unnecessary details so you can focus on what is really relevant for your business.</p>

            <h4>Press release keyword monitoring</h4>
            <p>Want to know if a competitor has introduced a new product or has canceled an existing product line?
            Use the keyword monitor in Hexowatch to keep on top of this information by monitoring the press release search
              results and industry websites for your brand terms.</p>

            <p>You can also use content monitoring or visual page monitoring to receive alerts when key suppliers make announcements,
                or when competitors update their press pages or when industry websites release news.</p>
            <h4>Go beyond press & industry news monitoring</h4>
            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every change and turn any
              website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
